module controllers {
    export module consigment {
        interface IConsignmentCreateScope extends ng.IScope {
        }

        export class consignmentCreateCtrl
        {
            static $inject = ["$scope", "$rootScope", "consignmentService", "$timeout", "generalService", "$state", "entityService", "shipperService", "consigneeService", 
            "counterService", "consignmentTypeService", "$q", "bsLoadingOverlayService", "routeCodeService","supplierService", "customerService", 'currencyService', 
            'commercialInvoiceService',
            "isInbound",];

            //Entity Variables
            ownerEntityId: number;
            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;

            //Shipper Variables
            shipperBranch: interfaces.applicationcore.IDropdownModel;
            shipperList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            //Consignee Variables
            consigneeBranch: interfaces.applicationcore.IDropdownModel;
            consigneeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            //Supplier Variables
            supplierModel: interfaces.applicationcore.IDropdownModel;
            supplierList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            //Customer Variables
            customerModel: interfaces.applicationcore.IDropdownModel;
            customerList: Array<interfaces.applicationcore.IDropdownModel>;

            DefaultCurrency: interfaces.applicationcore.IDropdownModel;
            Currency: interfaces.applicationcore.IDropdownModel;
            CurrencyDefaulted: boolean = false;

            customNumber: string;
            invoiceCustomNumber: string;

            //Counter Variables
            consignmentCounterId: number;
            counterTypeDefer: ng.IDeferred<any>;
            consignmentCounterList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            consignmentCustomNumber: boolean = false;

            customNumberCheck: boolean = false;
            customInvoiceNumberCheck: boolean = false;

            //Consignment Type Variables
            consignmentTypeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            consignmentTypeDefer: ng.IDeferred<any>;
            TypeId: number;
            consignmentTypeDefaults: interfaces.consignment.ConsignmentTypeDefaults;

            //Route Code
            loadingCountryID: number;

            consignmentTypeRouteCode : interfaces.applicationcore.IDropdownModel;
            routeCode: interfaces.applicationcore.IDropdownModel;
            routeCodeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            loadPromises: ng.IPromise<any>[];
            
            allowCustomConsignmentCounter : boolean = false;

            LockShipper: boolean = false;
            LockConsignee: boolean = false;
            LockSupplier: boolean = false;
            LockCustomer: boolean = false;

            manId: number;

            //Commercial Invoice Variables
            invoiceCounterId: number;
            invoiceCounterTypeDefer: ng.IDeferred<any>;
            invoiceCounterList: Array<interfaces.applicationcore.IDropdownModel>;
            invoiceDate: moment.Moment =  moment().utc();

            currencyCorrectCheck: boolean = false;

            constructor(private $scope: IConsignmentCreateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private consignmentService: interfaces.consignment.IConsignmentService,
                private $timeout: ng.ITimeoutService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private shipperService: interfaces.master.IShipperService,
                private consigneeService: interfaces.master.IConsigneeService,
                private counterService: interfaces.master.ICounterService,
                private consignmentTypeService: interfaces.logistics.IConsignmentTypeService,
                private $q: ng.IQService,
                private bsLoadingOverlayService,
                public routeCodeService: interfaces.master.IRouteCodeService,
                private supplierService: interfaces.master.ISupplierService,
                private customerService: interfaces.master.ICustomerService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                private isInbound: boolean,
            )
            {
                this.manId = this.$state.params["manId"];
                //Get Entities Data
                this.loadPromises = [];

                this.loadPromises.push(this.loadEntities());
                this.loadPromises.push(this.getCurrentEntity());
                this.loadPromises.push(this.getCurrentCountryID());               


                this.consignmentTypeDefer = $q.defer();
                this.loadPromises.push(this.consignmentTypeDefer.promise);

                bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return $q.all(this.loadPromises);
                });
            }

            //Loading Consignment Types
            loadConsignmentTypes() {

                this.consignmentTypeService.getDropdownList(this.ownerEntityId, this.isInbound).query({}, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    if (this.consignmentTypeDefer) {
                        this.consignmentTypeDefer.resolve();
                    }

                    this.consignmentTypeList = result;

                    var defaultType = _.find(this.consignmentTypeList, function (o) { return o.Selected; });

                    if (defaultType) {
                        this.TypeId = defaultType.Id;
                        this.consignmentTypeChanged();
                    }

                }, (failureData) => {
                    if (this.consignmentTypeDefer) {
                        this.consignmentTypeDefer.reject();
                    }
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.consignmentTypeList = <ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>>[];
                });
            }

            //Loading Consignment Counters
            loadConsignmentCounter() {
                
                this.counterService.getDropdownList().query({
                    ownerEntityId: this.ownerEntityId,
                    counterTypeId: this.isInbound?Enum.EnumCounterTypes.ImportConignment:Enum.EnumCounterTypes.Consignment, //Import or Export Counter Type
                    isInbound: this.isInbound
                }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    this.consignmentCounterList = result;

                    if (this.counterTypeDefer) {
                        this.counterTypeDefer.resolve();
                    }
                  
                    angular.forEach(result, (o) => {
                        if (o.Selected) {
                            this.consignmentCounterId = o.Id;
                            
                        }
                    });

                }, (failureData) => {

                    if (this.counterTypeDefer) {
                        this.counterTypeDefer.reject();
                    }

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            customInvoiceCounterChanged() {
                this.invoiceCustomNumber = '';
            }

            //Loading Invoice Counters
            loadInvoiceCounter() {

                let deferred = this.$q.defer<boolean>();

                if (this.ownerEntityId) {

                    this.counterService.getDropdownList().query({
                        ownerEntityId: this.ownerEntityId,
                        counterTypeId: this.isInbound?Enum.EnumCounterTypes.ImportCommercialInvoice:Enum.EnumCounterTypes.CommercialInvoice,
                        isInbound: this.isInbound
                    }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                        this.invoiceCounterList = result;

                        angular.forEach(result, (o) => {
                            if (o.Selected) {
                                this.invoiceCounterId = o.Id;
                            }
                        });
                      
                        if (this.invoiceCounterTypeDefer)
                            this.invoiceCounterTypeDefer.resolve()
                      
                            deferred.resolve(true);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        deferred.resolve(true);
                    })
                } else {
                    deferred.resolve(true);
                }

                return deferred.promise;
            }            

            //Loading Entities
            loadEntities() {
                return this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                    this.loadRouteDefaults();
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            getCurrentEntity() {
                return this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;

                    this.loadConsignmentTypes();
                    this.loadConsignmentCounter();
                    this.loadInvoiceCounter();
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            getCurrentCountryID()
            {
                return this.entityService.getEntityCountry().get({
                    entityId: this.ownerEntityId
                }, (result: interfaces.applicationcore.ICountry) => {
                    this.loadingCountryID = result.Id;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Loading the Shippers
            loadShippers(searchText: string) {
                return this.shipperService.getShipperDropdown(this.ownerEntityId, searchText).query({
                }, () => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Loading the Consignees
            loadConsignees(searchText: string) {
                return this.consigneeService.getDropdownList(this.ownerEntityId, searchText).query({
                }, () => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

             //Load Suppliers
             getSuppliers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.supplierService.getSupplierDropdown(this.ownerEntityId, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            //Load Customers
            getCustomers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.customerService.getDropdownList(this.ownerEntityId, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadCurrencies() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });


                return defered.promise;
            }

            customCounterChanged() {

                this.consignmentCustomNumber = !this.consignmentCustomNumber;
                this.customNumber = '';

                if (this.consignmentCustomNumber)
                {
                    this.consignmentCounterList = undefined;
                    this.consignmentCounterId = undefined;

                } else if (this.consignmentCounterList == undefined) {

                    var promises = [];

                    this.counterTypeDefer = this.$q.defer();
                    promises.push(this.counterTypeDefer.promise);
                    this.loadConsignmentCounter();

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.$q.all(promises);
                    });
                }
            }


            consigneeChanged(model: interfaces.applicationcore.IDropdownModel) {

                //Always try and get Route default from Consignee
                    this.loadConsigneeDefaults(model.Id);                
            }

            loadConsigneeDefaults(consigneeId: number) {
                return this.consigneeService.getConsigneeDefaults(consigneeId).get({

                }, (data: interfaces.master.IConsigneeDefaults) => {
                    if (data.Route) {
                        this.routeCode = data.Route;
                    }

                    if (data.Customer){
                        this.customerModel = data.Customer;
                        this.customerChanged(this.customerModel);
                    }
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            shipperChanged(model: interfaces.applicationcore.IDropdownModel) {
                //Always try and get Route default from Shipper
                    this.loadShipperDefaults(model.Id);                
            }

            supplierChanged(model: interfaces.applicationcore.IDropdownModel){
                if (model && this.isInbound) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.commercialInvoiceService.getSupplierDefault(this.ownerEntityId, model.Id, this.isInbound, true).get((result) => {
                            if (result.Currency) {
                                this.Currency = result.Currency;
                                this.DefaultCurrency = result.Currency;
                                this.CurrencyDefaulted = true;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                }
            }

            customerChanged(model: interfaces.applicationcore.IDropdownModel){
                if (model && !this.isInbound) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.commercialInvoiceService.getCustomerDefaultCurrency( model.Id).get((result:interfaces.applicationcore.IDropdownModel) => {
                            if (result) {
                                this.Currency = result;
                                this.DefaultCurrency = result;
                                this.CurrencyDefaulted = true;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                }
            }

            loadShipperDefaults(shipperId: number){
                return this.shipperService.getShipperDefaults(shipperId).get({

                }, (data: interfaces.master.IShipperDefaults) => {
                    if (data.Route) {
                        this.routeCode = data.Route;
                    }                  
                    
                    if (data.IsSupplier){
                        this.supplierModel = this.shipperBranch;
                        this.supplierChanged(this.supplierModel);
                    }

                    if (data.Supplier){
                        this.supplierModel = data.Supplier;
                        this.supplierChanged(this.supplierModel);
                    }

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            //Reload everything for the new Entity
            ownerEntityChanged()
            {
   
                var promises = [];
                this.shipperBranch = undefined;
                this.TypeId = undefined;
                this.consignmentCounterId = undefined;

                this.shipperList = undefined;
                this.consignmentCounterList = undefined;

                this.consignmentTypeDefer = this.$q.defer();
                promises.push(this.consignmentTypeDefer.promise);
                this.loadConsignmentTypes();

                this.counterTypeDefer = this.$q.defer();
                promises.push(this.counterTypeDefer.promise);
                this.loadConsignmentCounter();

                this.invoiceCounterTypeDefer = this.$q.defer();
                promises.push(this.invoiceCounterTypeDefer.promise);
                this.loadInvoiceCounter();
                

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    
                   return this.$q.all(promises);
                    });
            }

            consignmentTypeChanged() {

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.consignmentService.getConsignmentTypeDefaults(this.TypeId).get((data: interfaces.consignment.ConsignmentTypeDefaults) => {
                        this.consignmentTypeDefaults = data;
                        
                        this.shipperBranch = data.Shipper ? data.Shipper : undefined;
                        this.consigneeBranch = data.ShipTo ? data.ShipTo : undefined;
                        this.customerModel = data.Customer ? data.Customer : undefined;
                        this.supplierModel = data.Supplier ? data.Supplier : undefined;


                        this.LockShipper = false;
                        this.LockConsignee = false;
                        this.LockCustomer = false;
                        this.LockSupplier = false;
                        
                        if (data.LockShipper && this.shipperBranch){
                            this.LockShipper = true;
                        }

                        if (data.LockConsignee && this.consigneeBranch){
                            this.LockConsignee = true;
                        }

                        if (data.LockCustomer && this.customerModel){
                            this.LockCustomer = true;
                        }  
                        
                        if (data.LockSupplier && this.supplierModel){
                            this.LockSupplier = true;
                        }                           

                        this.routeCode = data.Route ? data.Route : undefined;
                        this.consignmentTypeRouteCode = data.Route ? data.Route : undefined;
                        
                        if (data.consignmentCounterId && data.consignmentCounterId>0){
                            this.consignmentCounterId = data.consignmentCounterId ? data.consignmentCounterId : undefined;
                        }

                        this.customNumber = '';
                        this.customNumberCheck = false;

                        this.customInvoiceNumberCheck =false;
                        this.invoiceCustomNumber = '';

                        this.allowCustomConsignmentCounter = data.AllowCustomConsignmentCounter;

                        //Always try and get Route default from Consignee
                        if (this.consigneeBranch) {
                            this.loadConsigneeDefaults(this.consigneeBranch.Id);  
                        }

                        if (this.supplierModel){
                            this.supplierChanged(this.supplierModel);
                        }
                        if (this.customerModel){
                            this.customerChanged(this.customerModel);
                        }

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise
                });
                
            }

            //Loading Routes
            loadRouteDefaults()
            {
                if (this.ownerEntityId) {
                    return this.routeCodeService.getDropdownListAll(this.ownerEntityId, this.isInbound).query((result) => {
                        this.routeCodeList = result;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
                
            }

            CreateConsignment() {

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {

                    var params = <interfaces.consignment.IConsignmentCreateParameters>{
                        ownerEntityId :  this.ownerEntityId,
                        typeId: this.TypeId,
                        shipperId: this.shipperBranch.Id,
                        consigneeId: this.consigneeBranch.Id,
                        supplierId: this.supplierModel.Id,
                        customerId:  this.customerModel.Id,           
                        routeCodeId:  this.routeCode ? this.routeCode.Id : undefined,
                        customNumber: this.customNumber,
                        customInvoiceNumber: this.invoiceCustomNumber,
                        isInbound:  this.isInbound,
                        counterId: this.consignmentCounterId ? this.consignmentCounterId : 0,
                        invoiceCounterId: this.invoiceCounterId ? this.invoiceCounterId : 0,
                        consignmentId: null,                    
                        manId:  this.$state.params["manId"],
                        invoiceCurrencyId: this.Currency.Id,
                        invoiceDate: this.invoiceDate
                    };

                    return this.consignmentService.create(                    
                    ).save(params
                        
                    , (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);

                        if (!result.HasErrorMessage) {
                            if (this.$state.params["manId"]) {

                                if (this.isInbound) {
                                    this.$state.go("auth.Manifests.Update.ConsignmentImport", { conId: Number(result.ID) });
                                }
                                else {
                                    this.$state.go("auth.Manifests.Update.ConsignmentExport", { conId: Number(result.ID) });
                                }
                            } else {
                                if (this.isInbound) {
                                    this.$state.go("auth.ConsignmentImports.Update", { conId: Number(result.ID) });
                                }
                                else {
                                    this.$state.go("auth.ConsignmentExports.Update", { conId: Number(result.ID) });
                                }
                            }
                        }

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                });
            }

            //Navigation
            close() {
                this.$state.go("^");
            }

            backSales()
            {
                this.$state.go("^.auth.ConsignmentImports.Update")
            }
        }

        angular.module("app").controller("consignmentCreateCtrl", controllers.consigment.consignmentCreateCtrl);
    }
}
